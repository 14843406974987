@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
@import './AppDemo.scss';
@import "@fortawesome/fontawesome-pro/css/all.css";

@import url('https://unpkg.com/leaflet@1.5.1/dist/leaflet.css');
@import url('//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css');


/* home */

.p-accordion-header-text{
    font-size: 16px;
    font-weight: 600;
}

.content-accordion-home{
    font-size: 16px;
    text-align: justify;
}

.search-section{
    color: #fff;
    text-align: center;
    background: #ccc;
    margin-bottom: 30px;
    font-weight: bold;
}

.input-search-home{
    width: 50%;
}

/* search area */

.search-bar{
    width: 100%;
    margin-bottom: 20px;
    border-top: 3px solid #fff;
    background: #ababab;
}

.display-search{
    display: block;
}

.layout-container.layout-top-medium .layout-content{
    margin-top: 0;
}

@media screen and (min-width: 1024px) {

    .layout-breadcrumb ul {
        padding-left: 0px;
    }

}

.search-bar-layout-content{
    padding: 14px 100px;
}

.p-fieldset .p-fieldset-content {
    padding: 0px;
}

.our-layout-content{
    padding: 0 100px;
}

.analytics-layout-content,
.fair-layout-content{
    padding: 8px 100px;
}




.analytics-layout-content .p-tabview .p-tabview-panels,
.analytics-layout-content .p-tabview .p-tabview-nav,
.analytics-layout-content .p-tabview .p-tabview-nav .p-tabview-nav-link {
    background-color: #f4f4f7!important;
}


.analytics-layout-content .p-tabview-title {
   font-size: 16px;
}

.fair-layout-content .p-tabview-title {
    font-size: 15px;
}

@media screen and (max-width: 1024px) {
    .search-bar-layout-content {
        padding: 14px 1em;
    };

    .our-layout-content {
        padding: 2em 1em;
    };

    .analytics-layout-content,
    .fair-layout-content {
        padding: 8px 1em;
    };
}

.search-buttons-container{
    width: auto;
}

.input-search{
    width: 100%;
}

.input-search-hidden{
    width: 100%;
    color: #ababab;
    background-color: #d6d6d6;
   // pointer-events: none;
    cursor: not-allowed;

}

/* search area and - or button*/

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button{
    background: #5facb6;
    color: #fff;
    margin-left: 7px;
    padding: 14px 0;
    border-color: #fff;
}

.p-togglebutton.p-button.p-highlight:hover,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover{
    background:#5facb6;
    border-color: #fff;
    color: #fff;
}

.p-togglebutton.p-button.p-highlight:focus,
.p-togglebutton.p-button:focus{
    background: #5facb6;
    border-color: #fff;
    color: #fff;
}

.p-button:enabled:hover{
    background: #0595b6;
}

.p-button:enabled:focus{
    background: #057091;
}

/* search area - fieldset advanced search */

.fieldset-advanced-search{
    margin-top: 10px;
    border: 1px solid #ffffff;
    background: #ffffff00;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a{
    color: #fff;
    background: #0595b6;
}

/* tab menu*/

.tableview-tab i{
    margin-right: 7px;
}

/* filters left */

.filter-container{
    width: 500px;
}

//.p-button.p-button-icon-only{
//    width: 5rem;
//}

.filter-button{
    background: #0595b6;
    color: #fff;
  //  display: none;
}

.fad.fa-filter.p-c.p-button-icon-left:after{
    color: #fff;
    opacity: 1;
}

@media screen and (max-width: 1024px) {
    .filter-container{
        display: none;
    }

    .filter-button{
        display: block;
    }
}

.cloud-heading{
    margin-bottom: 10px;
    background-color: #6d6e71;
    border-radius: 5px 5px 5px 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 0 solid;
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
}

.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right,
.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right{
    color: #fff;
}

/* breadcrumb */

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
    color: #fff;
}

.p-breadcrumb ul li:last-child .p-menuitem-text{
    color: #fff;
}

.p-breadcrumb ul li.p-breadcrumb-chevron{
    color: #fff;
}

/* table */

.p-calendar.p-component.p-inputwrapper.p-column-filter > input,
.p-inputtext.p-component.p-column-filter,
.p-multiselect-label.p-placeholder,
.p-multiselect-label.p-multiselect-items-label,
.p-multiselect .p-multiselect-label{
    padding: 10px;
}

.p-input-icon-left{
    margin-left: 10px;
}

input[type="search"].p-inputtext.p-component{
    padding: 10px 0 10px 35px;
}

.p-calendar .p-datepicker,
.p-datepicker.p-component.p-connected-overlay-enter-done,
.p-multiselect .p-multiselect-panel,
.p-multiselect-header,
.p-multiselect-items-wrapper,
.p-multiselect-items p-component{
    max-width: 18rem;
}

.image-table{
    width: 28px;
}

.image-access-asset{
    width: 28px;
    float: right;
    margin-right: 6px;
}

.div-access-asset{
    width: calc(100% - 185px);
}

.p-datatable-header {
    background: #ececec!important;
    border: 1px solid #ababab!important;
    border-width: 0px 0 2px 0!important;
    padding-top: 20px!important;
    padding-bottom: 12px!important;
    padding-left: 24px!important;
    padding-right: 16px!important;
}

.title-column{
    display: flex;
    align-items: center;
}

.title-thumbnail{
    margin-right: 10px;
}

.p-inputtext.p-component.p-column-filter{
    width: 100%
}

.p-multiselect-panel.p-component.p-connected-overlay-enter-done[style]{
    left: 0 !important;
}

.p-multiselect.p-component.p-inputwrapper.p-column-filter{
    width: 100%;
}

.p-multiselect-panel .p-multiselect-header{
    background: #e7e7e7;
}

#popup_menu{
    top: 60px !important;
    width: 95px;
    left: revert !important;
}

.exportButton{
    background: #057091;
}

.exportButton:hover{
    background: #0595b6;
}

/* landing */

.landing-container, .home-container{
    margin-top: 32px;
}

.landing-thumbnail-fixed{
    width: 120px;
}

.p-grid.our-layout-content.landing-container,
.our-layout-content.home-container{
    margin-right: 0;
}

.cards-margin-bottom{
    margin-bottom: 16px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 0px;
}

.citation{
    font-size: 15px;
    padding-left: 10px;
    text-align: justify;
}

h2.h2-landing{
    font-size: 22px;
    font-weight: 700;
    padding-left: 0.5rem;
}

.h2-title{
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    font-size: 22px;
    font-weight: 700;
}

.h2-landing-citation,
.h2-landing-project,
.h2-landing-authors,
.h2-landing-contact{
    padding-left: 0;
    font-size: 22px;
    font-weight: 700;
}

h2.h2-landing.tagcloud{
    text-align: center;
}

.div-align-left{
    text-align: left;
}

.p-type-landing{
    font-size: 16px;
    font-weight: 300;
    color: #979797;
    margin: 0px;
}

.p-project-landing{
    font-size: 16px;
    font-weight: 300;
    color: #979797;
    margin-top: 30px;
}

.p-project-landing{
    margin-bottom: 0;
}

.p-publishers-landing{
    font-size: 16px;
    font-weight: 500;
}

.p-providers-landing{
    font-size: 14px;
    font-weight: 500;
}

.p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-center.divider-landing{
    font-size: 13px;
    background: #e3e3e3;
    padding: 0.5px;
    margin-bottom: 10px;
}

.h2-summary-landing{
    font-size: 22px;
    font-weight: 700;
}

.summary-landing{
    font-size: 18px;
}

#agrovoc_landing,
#keyword_landing{
    width: 100%;
    height: 250px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content{
    padding: 0;
}

.expand-table-landing {
    text-align: justify!important;
}

.p-datatable .p-datatable-thead > tr > th.expand-table-landing{
    padding: 0;
    border: none;
}

.p-datatable .p-datatable-tbody > tr > td.expand-table-landing{
    border: none;
}

/* access rights modal*/

.access-rights-view{
    color: #457fca;
    cursor: pointer;
}

p.terms-of-use{
    font-weight: bold;
}

/* dynamic divs */

div.margin-top-divs-landing{
    margin-bottom: 16px;
}

div.margin-bottom{
    margin-bottom: 8px!important;

}

.title-foreign-landing,
.title-project-landing{
    font-size: 18px;
    font-weight: 500;
}

.title-project-landing{
    margin-top: 0;
}

.title-english-landing{
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
}

.agent ~ .agent::before{
    content: '; ';
}

.icons-license{
    padding-right: 6px;
}

.license-link{
    color: #149ad4;
    //font-weight: 900;
    font-size: 24px;
    //font-family: "Chivo script=latin rev=3" !important;
}

/* fair scoring*/

div.fair-scoring-div tspan{
    font-family: "Roboto Mono" !important;
}

.fair-scoring-align,
.altmetric-embed,
.dimen-badge{
    display: flex;
    justify-content: center;
}

/* query builder*/

.query-button{
    color: #ffffff;
    background: #5facb6;
    border: 0 none;
    padding: 8px;
    font-size: 14px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
    margin-left: 7px;
    cursor: pointer;
}

.layout-top {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}

@media screen and (max-width: 1024px) {
    .layout-footer {
        padding: 0px;
    }

    .footer-grid {
        margin-right: 0px;
        margin-left: 0px;
    }
}

.layout-footer .p-col {
padding: 20px 0px 0px;
}

.footer-divider{
background: #fff;
margin: 10px 0!important;
}

.footer-menu {
color: #fff;
padding-right: 10px;
cursor: pointer;
}

.border-right{
border-right: 1px solid #fff;
margin-right: 10px;
}

.license-icons{
color: #fff;
font-size: 23px;
margin-left: 10px;
}

.logo-footer{
height: 35px;
vertical-align: middle;
}

.footer-licenses{
display: table-footer-group;
}

.privacy-container{
padding:10px;
border:1px solid black;
}

.modal-paragraph{
text-align: justify;
text-justify: inter-word;
}

.footer-anchor-link{
color: #0595b6;
font-weight: bold;
}

.p-dialog .p-dialog-header {
background-color: #054769;
}

.p-dialog .p-dialog-header .p-dialog-title {
color: white;
font-size: 22px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
color: white;
}


.p-dialog .p-dialog-content {
padding-bottom: 20px;
padding-left: 20px;
padding-right: 20px;
padding-top: 4px;

background-color: #f4f4f7;
}

.analytics-card {
    margin-top: 16px;
    margin-bottom: 0px;
    margin-left: 4px;
    margin-right: 4px;
}

.p-tree {
padding: 0rem!important;
}
.p-tree .p-tree-container .p-treenode {
padding: 0rem!important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
padding: 0rem!important;
}

.p-card .p-card-body {
    padding-top: 8px;
    padding-bottom: 0px;
}

/* DataTable */


.p-column-title{
    font-weight:600!important;
}


.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-viewColumn {
        width: 100%!important;
    }

    .datatable-NameColumn {
        padding-top: 8px!important;
    }


    .expand-table-landing{
        width: 100%!important;
        border-bottom: 0px!important;
        padding: 7px!important;

    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: calc(100% - 3em);
        padding-top: 0px;
        //width: 100%;
        float: left;
        //clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

.home-icons {
    --fa-secondary-opacity: 1;
    --fa-primary-opacity: 0.7;
    --fa-primary-color: #f7941d;
    --fa-secondary-color: #7e848d;
 }

.home-icons-inv {
    --fa-secondary-opacity: 0.7;
    --fa-primary-color: #7e848d;
    --fa-secondary-color: #f7941d;
}

.buttons-home {
    padding: 40px 50px;
    font-size: 18px;
    background: #f4b45f;
    width: 100%;
    max-width: 340px;
    height: 100%;
    max-height: 200px;
    box-sizing: border-box;
    margin-left: 10px;
    border: 2px solid #f4b45f;
    font-family: "Montserrat";
}

.buttons-home:enabled:hover,
.buttons-home:enabled:focus,
.buttons-home:enabled:active{
    background: #fff;
    border: 2px solid #f4b45f;
    color: #f4b45f;
}

#tvhome.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #f4b45f;
}

#tvhome.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #f4b45f;
}

.rule.queryBuilder-invalid .rule-value {
    border: 2px solid red;
}

.ruleGroup.queryBuilder-invalid > .ruleGroup-body::before{
    content:"Group is empty!";
    color: red;
    font-weight: bold;
    position:relative;
    top: 5px;
}


.ruleGroup {
    border: 1px  solid #cddc39;
    border-radius: 4px;
    background: #0595b633;
    margin: 6px;
}


/* search area - search button */

.search-btn {
    padding: 15px;
    margin-top: 6px;
    margin-right: 6px;

    background: #057091;
}

.queryBuilder-invalid ~ .search-btn {
    color: red;
}


.hg-title{
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    font-size: 22px;
    font-weight: 700;
}

.hg-subtitle{
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
}


.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    pointer-events: none;
}


.fieldset-advanced-search-guide{
    margin-top: 10px;
    border: 1px solid #054769;
    background: #ffffff00;
}


.leaflet-container {
    width: 100%;
    height: 50vh;
}

.large-map .leaflet-container {
    width: 100%;
    height: 70vh;
    max-height: 800px;
}

.p-sidebar {
    z-index: 10000 !important;
}


#geodatasetselection::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    display: block;
}

#geodatasetselection::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
